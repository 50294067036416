<template>
  <div class="entry-form__list">
    <div class="entry-form__item">
      <input
        v-model.trim.lazy="$v.name.$model"
        :class="{
          error: $v.name.$error || sendError,
          valid: name && !$v.name.$error,
        }"
        :placeholder="$t('auth.name')"
        class="input-entry"
        required
        type="text"
      />
      <div v-if="$v.name.$error" class="error-list__item">
        {{ $t("required_field") }}
      </div>
    </div>
    <div class="entry-form__item">
      <input
        v-model.trim.lazy="$v.email.$model"
        :class="{
          error: $v.email.$error || sendError,
          valid: email && !$v.email.$error,
        }"
        class="input-entry"
        placeholder="E-mail"
        required
        type="email"
      />
      <div v-if="$v.email.$error" class="error-list__item">
        {{ $t("auth.wrong_registration_email") }}
      </div>
    </div>
    <div class="entry-form__item">
      <input
        v-model.trim.lazy="$v.password.$model"
        :class="{
          error: $v.password.$error || sendError,
          valid: password && !$v.email.$error,
        }"
        :placeholder="$t('auth.password')"
        :type="inputType"
        autocomplete="off"
        class="input-entry password-field"
        required
      />
      <div v-if="$v.password.$error" class="error-list__item">
        {{ $t("min_len_pass", { number: "3" }) }}
      </div>
      <div class="switch-password">
        <button class="btn btn-empty" @click="toggleType(false)">
          <svg
            v-if="inputType === 'password'"
            v-svg
            role="presentation"
            size="0 0 18 16"
            symbol="view-1"
          ></svg>
          <svg
            v-else
            v-svg
            role="presentation"
            size="0 0 18 16"
            symbol="view-2"
          ></svg>
        </button>
      </div>
    </div>
    <div class="entry-form__item">
      <input
        v-model.trim.lazy="$v.repeatPassword.$model"
        :class="{
          error: $v.repeatPassword.$error || sendError,
          valid: repeatPassword && !$v.email.$error,
        }"
        :placeholder="$t('auth.password_confirm')"
        :type="inputTypeConfirm"
        autocomplete="off"
        class="input-entry password-field"
        required
      />
      <div v-if="$v.repeatPassword.$error" class="error-list__item">
        {{ $t("auth.password_confirm_wrong") }}
      </div>
      <div class="switch-password">
        <button class="btn btn-empty" @click="toggleType(true)">
          <svg
            v-if="inputTypeConfirm === 'password'"
            v-svg
            role="presentation"
            size="0 0 18 16"
            symbol="view-1"
          ></svg>
          <svg
            v-else
            v-svg
            role="presentation"
            size="0 0 18 16"
            symbol="view-2"
          ></svg>
        </button>
      </div>
    </div>
    <div class="entry-form__item">
      <input
        v-model="telegram"
        :placeholder="$t('auth.telegram')"
        class="input-entry"
        required
        type="text"
      />
    </div>
    <div class="entry-form__item">
      <input
        v-model="skype"
        :placeholder="$t('auth.skype')"
        class="input-entry"
        required
        type="text"
      />
    </div>
    <div class="d-flex justify-content-center">
      <vue-recaptcha
        ref="vueRecaptcha"
        :loadRecaptchaScript="true"
        :sitekey="capChaKey"
        class="pt-2 pb-4 d-flex justify-content-center w-100"
        size="normal"
        tabindex="0"
        theme="light"
        @expire="recaptchaExpired"
        @expired="expiredMethod"
        @fail="recaptchaFailed"
        @verify="recaptchaVerified"
      />
    </div>
    <div class="entry-form__item _submit">
      <button class="btn _w100" type="submit" @click="registration">
        {{ $t("auth.sign_up") }}
      </button>
    </div>
    <div class="entry-form__item _privacy mb-0">
      <router-link to="/reset-password">{{
        $t("auth.forgot_password")
      }}</router-link>
    </div>
    <notifications group="errorAuth" />
  </div>
</template>

<script>
import { VueRecaptcha } from "vue-recaptcha";
import { validationMixin } from "vuelidate";
import {
  email,
  maxLength,
  minLength,
  required,
  sameAs,
} from "vuelidate/lib/validators";
import AuthService from "@/api/serviceAuth";

export default {
  name: "Registration",
  mixins: [validationMixin],
  components: {
    VueRecaptcha,
  },
  data() {
    return {
      inputType: "password",
      inputTypeConfirm: "password",
      email: "",
      name: "",
      telegram: "",
      skype: "",
      password: "",
      repeatPassword: "",
      recaptcha_response: "",
      sendError: false,
      referralId: false,
      utm_source: "",
    };
  },
  validations: {
    name: {
      required,
    },
    email: {
      required,
      email,
    },
    password: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(32),
    },
    repeatPassword: {
      sameAsPassword: sameAs("password"),
    },
  },
  methods: {
    registration() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$notify({
          group: "errorAuth",
          title: this.$t("sent_error"),
          text: this.$t("fields_invalid"),
          ignoreDuplicates: true,
          type: "error",
        });
        return;
      }
      if (!this.recaptcha_response) {
        this.$notify({
          group: "errorAuth",
          title: this.$t("sent_error"),
          text: this.$t("toast_error_captcha"),
          ignoreDuplicates: true,
          type: "error",
        });
        return;
      }
      const params = {
        name: this.name,
        email: this.email,
        password: this.password,
        recaptcha_response: this.recaptcha_response,
        telegram: this.telegram,
        skype: this.skype,
        referral_id: this.referralId ? this.referralId : 0,
        utm_source: this.utm_source,
      };
      AuthService.registration(params).then(
        (resp) => {
          if (resp.data.token && resp.data.refresh_token) {
            localStorage.setItem("authToken", resp.data.token);
            localStorage.setItem("refreshToken", resp.data.refresh_token);
            this.$router.push("/dashboard");
          } else {
            this.$notify({
              group: "errorAuth",
              title: this.$t("sent_error"),
              text: resp.message,
              ignoreDuplicates: true,
              type: "error",
            });
            this.recaptchaFailed();
          }
        },
        (error) => {
          console.log("error ======= ", error);
        }
      );
    },
    expiredMethod() {
      this.recaptcha_response = "";
    },
    recaptchaVerified(response) {
      this.recaptcha_response = response;
    },
    recaptchaExpired() {
      this.$refs.vueRecaptcha.reset();
      this.recaptcha_response = "";
    },
    recaptchaFailed() {
      this.$refs.vueRecaptcha.reset();
      this.recaptcha_response = "";
    },
    toggleType(el) {
      const element = el ? "inputTypeConfirm" : "inputType";
      this[element] === "password"
        ? (this[element] = "text")
        : (this[element] = "password");
    },
  },
  computed: {
    capChaKey() {
      return "6LdWqHkUAAAAABxWRXeYYXWW6F2F3LswjImcYoWW";
    },
  },
};
</script>

<style lang="scss" scoped>
.switch-password .btn.btn-empty {
  padding: 0;
}
</style>